@import url("https://fonts.cdnfonts.com/css/air-strip-arabic");
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "Air Strip Arabic", sans-serif;
}

:root {
  --mainColor: #232323;
  --mainColorLight: #5767aa;
  --secondaryColor: #db2b39;
  --textColor: #eee;
}

header {
  z-index: 999;
  position: fixed;
  width: 100%;
  top: -1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  padding: 0 2rem;
  background-color: var(--mainColor);
  color: var(--textColor);
  box-shadow: 4px 5px 5px #333;
}

nav a {
  margin: 0 1rem;
  color: var(--textColor);
  text-decoration: none;
  transition: 0.3s ease;
  font-size: 18px;
  margin-right: 5rem;
}

nav a:hover {
  color: var(--secondaryColor);
}

header .nav-btn {
  padding: 5px;
  cursor: pointer;
  background: transparent;
  border: none;
  outline: none;
  color: var(--textColor);
  visibility: hidden;
  opacity: 0;
  font-size: 1.6rem;
}

header div,
nav {
  display: flex;
  align-items: center;
}

@media only screen and (max-width: 1024px) {
  header .nav-btn {
    visibility: visible;
    opacity: 1;
  }

  header nav {
    position: fixed;
    top: -100vh;
    left: 0;
    height: 70%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 1.5rem;
    background-color: var(--mainColor);
    transition: 1s;
    box-shadow: 4px 5px 5px #333;
  }

  header .responsive_nav {
    transform: translateY(100vh);
  }

  nav .nav-close-btn {
    position: absolute;
    top: 1rem;
    left: 2rem;
  }

  nav a {
    font-size: 1.5rem;
  }
}
@media screen and (max-width: 789px) {
  nav a {
    margin: 0;
  }
}
.dropdown .btn {
  width: 140px;
}
.dropdown .btn.show {
  border: none;
}
.dropdown svg {
  color: #fff;
  font-size: 20px;
}
.dropdown .dropdown-toggle::after {
  display: none;
}
