@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

* {
  font-family: "Cairo", sans-serif;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  direction: rtl;
  font-family: "Air Strip Arabic", sans-serif;
  background-color: #232323;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
