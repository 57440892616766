.footer {
  background-color: #000;
  width: 100%;
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.footer_container {
  width: 100%;
}
.footer_container h2 {
  color: #fff;
  font-size: 35px;
  margin: 1rem 0;
}
.footer_container .social {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}
.footer_container .social svg {
  font-size: 25px;
  background-color: #999;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  padding: 10px;
  color: #000;
  transition: 0.3s ease;
  cursor: pointer;
}
.footer_container .social svg:nth-last-of-type(4):hover {
  background-color: #fffc00;
  scale: 1.05;
}
.footer_container .social svg:nth-last-of-type(3):hover {
  background-color: #1877f2;
  color: #fff;
  scale: 1.05;
}
.footer_container .social svg:nth-last-of-type(2):hover {
  background-color: #14171a;
  color: #fff;
  scale: 1.05;
}
.footer_container .social svg:nth-last-of-type(1):hover {
  background-color: #bc2a8d;
  color: #fff;
  scale: 1.05;
}
.footer_content {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin-top: 2rem;
}
.footer_content .footer_content_info {
  text-align: center;
}
.footer_content .footer_content_info h3 {
  font-size: 40px;
  color: #999;
}
.footer_content .footer_content_info p {
  font-size: 20px;
  color: #aaa;
}
.copyright {
  margin: 2rem 0 1rem 0;
  color: #999;
  font-size: 18px;
}
@media screen and (max-width: 789px) {
  .footer_content {
    flex-direction: column;
  }
  .footer_content_map .map {
    width: 350px;
  }
}
