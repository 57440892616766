body {
  background-color: #232323 !important;
}
.App {
  text-align: center;
  background-color: #232323;
}
.fixed_contact {
  width: fit-content;
  background-color: #999;
  padding: 1rem;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: fixed;
  bottom: 20px;
  right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  z-index: 999999;
}
.fixed_contact svg {
  font-size: 33px;
  color: #075e54;
}
