.about {
  background-color: #232323;
  padding: 1rem;
  color: #fff;
  width: 100%;
  text-align: center;
}
.about_content {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 1rem;
  position: relative;
}
.about_info {
  width: 50%;
}
.about_info h4 {
  font-size: 25px;
  margin-top: 1rem;
  line-height: 1.5;
}
.about_content img {
  width: auto;
  height: 350px;
}
.about_content .about_float {
  position: absolute;
  top: 143px;
  font-size: 3.5rem;
  color: #fff;
  font-weight: bold;
}
@media screen and (max-width: 1400px) {
  .about_content .about_float {
    left: 140px;
    top: 110px;
  }
}
@media screen and (max-width: 1200px) {
  .about_content .about_float {
    left: 100px;
  }
}
@media screen and (max-width: 958px) {
  .about_content .about_float {
    left: 85px;
    top: 110px;
  }
}
@media screen and (max-width: 789px) {
  .about_content {
    flex-direction: column-reverse;
  }
  .about_info {
    margin-bottom: 1rem;
  }
  .about_content img {
    width: 450px;
    height: 430px;
  }
  .about_info {
    width: 100%;
  }
  .about_info h4 {
    font-size: 22px;
  }
}
@media screen and (max-width: 489px) {
  .about_content img {
    width: 350px;
    height: auto;
  }
  .about_info h2 {
    font-size: 40px;
  }
  .about_info h4 {
    font-size: 18px;
  }
}
