.details {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 5rem;
}
.details_container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.product_image {
  width: 50%;
  padding: 10px;
}
.product_image img {
  width: 400px;
  cursor: pointer;
}
.product_content {
  width: 50%;
  padding: 10px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.product_content h3 {
  margin-bottom: 2rem;
}
.product_content p {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-size: 20px;
  line-height: 30px;
  font-family: Arial, Helvetica, sans-serif;
}
.social_product {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 2rem;
}
.social_product svg {
  font-size: 1.5rem;
  background-color: #aaa;
  color: #000;
  padding: 10px;
  width: 20px;
  height: 20px;
  transition: 0.3s ease;
  cursor: pointer;
}
.social_product svg:hover {
  background-color: #fff;
  scale: 1.05;
  border-radius: 6px;
}
.pre_desc {
  width: 100%;
  border-top: 1px solid #aaa;
  color: #fff;
  padding: 2rem 1rem 0 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.pre_desc pre {
  width: 55%;
  color: #ddd;
  font-size: 1rem;
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  line-height: 23px;
  display: flex;
  justify-content: start;
  align-items: center;
  line-height: 1.8;
}
@media screen and (max-width: 789px) {
  .details_container {
    flex-direction: column;
  }
  .product_image {
    text-align: center;
    width: 100%;
  }
  .product_image img {
    width: 250px;
  }
  .product_content {
    width: 100%;
  }
  .pre_desc pre {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: start;
    align-items: center;
  }
}
