.vape {
  width: 100%;
  padding: 0;
  margin: 0;
}
.vape_container {
  width: 100%;
  padding: 10px;
  margin: 0;
}
.vape_container > h2 {
  width: 100%;
  margin-top: 4.5rem;
  color: #fff;
}
.vape_container h2 span {
  color: #000000;
  font-size: 1.8rem;
  padding: 0px 10px;
  background-color: #999;
  border-radius: 16px;
}
.vape_list {
  margin-top: 2rem;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 10px;
}
.vape_list a {
  text-decoration: none;
}

.vape_list .vape_icon {
  color: #eee;
  font-size: 55px;
  width: 65px;
  height: 65px;
  padding: 1rem;
  position: absolute;
  left: 50%;
  text-align: center;
  background-image: linear-gradient(60deg, #777, #000);
  border-radius: 50%;
  animation: animationIcon 5s infinite linear;
  background-size: 500% 500%;
}
@keyframes animationIcon {
  0% {
    background-position: 0 85%;
  }
  50% {
    background-position: 100% 20%;
  }
  100% {
    background-position: 0 85%;
  }
}
.vape_item {
  width: 300px;
  height: auto;
  transition: 0.3s ease-in;
  cursor: pointer;
  margin-inline: auto;
  overflow: hidden;
}
.vape_item img {
  max-width: 100%;
  border-radius: 16px 16px 0 0;
  transition: 0.5s ease-in;
  background-color: #fff;
}
.vape_item:hover img {
  transform: rotateY(180deg);
  scale: 1.05;
  border-radius: 16px;
}
.vape_item .vape_item_content {
  color: #fff;
  background-color: #353535;
  border-radius: 0 0 16px 0;
  width: 100%;
  height: 80px;
}
.vape_item .vape_item_content h3 {
  color: #ccc;
  font-size: 1.2rem;
  padding: 5px;
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 30px;
}
.vape_item .vape_item_content p {
  letter-spacing: 0.5px;
  font-size: 1rem;
  padding: 5px;
  color: #898989;
}
.vape_list .loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  display: inline-block;
  position: relative;
  background: linear-gradient(0deg, rgba(115, 115, 115, 0.2) 33%, #fff 100%);
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
  top: 100%;
  right: 200%;
}
.loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 44px;
  height: 44px;
  border-radius: 50%;
  background: #263238;
}
@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.model svg {
  color: #fff;
}
.model_content {
  width: 50%;
  padding: 10px;
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.model_content h4 {
  color: #aaa;
  font-size: 1.8rem;
  margin-bottom: 2rem;
}
.model_content pre {
  white-space: pre-wrap;
  word-wrap: break-word;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 20px;
  line-height: 23px;
  height: 100%;
  overflow-y: hidden;
}
@media screen and (max-width: 989px) {
  .vape_list {
    grid-template-columns: 1fr 1fr 1fr;
  }
  .vape_list .loader {
    top: 100%;
    right: 150%;
  }
}
@media screen and (max-width: 789px) {
  .vape_list {
    grid-template-columns: 1fr 1fr;
  }
  .vape_item {
    width: 300px;
  }
  .vape_list .loader {
    top: 100%;
    right: 100%;
  }
  .model {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .vape_list .model img {
    height: 250px;
    width: 200px;
  }
  .model_content {
    width: 100%;
  }
  .model_content h4 {
    margin-bottom: 1rem;
  }
}
@media screen and (max-width: 589px) {
  .vape_list {
    grid-template-columns: 1fr 1fr;
  }
  .vape_item {
    width: 250px;
  }
  .vape_list .loader {
    top: 100%;
    right: 90%;
  }
  .vape_container h2 span {
    font-size: 1.1rem;
  }
}
@media screen and (max-width: 489px) {
  .vape_list {
    grid-template-columns: 1fr;
  }
  .vape_item {
    width: 320px;
  }
  .vape_list .vape_icon {
    top: 20%;
    left: 40%;
  }
  .vape_list .loader {
    top: 100%;
    right: 45%;
  }
}
