.banner1 {
  background-color: #232323;
  color: #fff;
  width: 100%;
}
.banner1_container {
  width: 100%;
}
.banner1_content .image_banner {
  z-index: 5;
  width: 100%;
  height: auto;
  margin-bottom: 1rem;
}
@media screen and (max-width: 489px) {
  .banner1_content .image_banner {
    height: auto;
  }
}
