* {
  font-family: "Cairo", sans-serif;
}
.show_images {
  width: 100%;
  background-color: #232323;
  color: #fff;
}
.show_images_container h2 {
  margin: 1rem 0;
  font-size: 40px;
  color: #ddd;
}
.show_images_container button {
  margin-bottom: 1rem;
  background-color: #585858;
  border: none;
  padding: 10px 20px;
  font-weight: bold;
}
.gallary {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1rem;
  flex-wrap: wrap;
  width: 100%;
}
.sections {
  width: 100%;
  position: relative;
}
.sections button {
  margin-right: 10px;
  transition: 0.5s ease;
  color: #000;
}
.sections button:hover {
  color: #fff;
  border-radius: 10px;
}
.sections ul {
  background-color: #888;
  padding: 10px 50px;
  border-radius: 16px;
  justify-content: center;
  align-items: center;
  display: flex;
  margin-inline: auto;
  width: fit-content;
  flex-direction: column;
  transition: 0.3s ease;
  position: absolute;
  left: 44.2%;
  top: 90%;
}
@media screen and (max-width: 1050px) {
  .sections ul {
    left: 43%;
  }
}
@media screen and (max-width: 850px) {
  .sections ul {
    left: 40%;
  }
}
@media screen and (max-width: 650px) {
  .sections ul {
    left: 31%;
  }
}
.sections ul.fade_out {
  opacity: 0;
  z-index: 1;
}
.sections ul.fade_in {
  opacity: 1;
  z-index: 500;
}
.sections ul li {
  list-style: none;
  padding: 10px;
  font-size: 18px;
  width: 100%;
}
.sections ul li a {
  color: #222;
  text-decoration: none;
  width: 100%;
  transition: 0.3s ease;
}
.sections ul li:hover a {
  color: #fff;
}
.gallary .pics {
  transition: 0.3s ease-in-out;
  cursor: pointer;
  margin-bottom: 1rem;
  width: 320px;
  background-color: #121212;
  border-radius: 16px;
}
.gallary .pics img {
  width: 100%;
  height: 370px;
  transition: 0.5s ease-in-out;
  border-radius: 16px 16px 0 0;
}
.gallary .pics:hover img {
  scale: 1.05;
  transform: rotateY(180deg);
}
.pics_content {
  background-color: #111012;
  border-radius: 16px;
}
.pics_content h3 {
  font-size: 1.2rem;
  color: #fff;
  padding: 10px 0;
}
.pics_content p {
  color: #999;
  font-size: 1.1rem;
  padding-bottom: 10px;
}
.dropdown-center .dropdown-menu a {
  text-decoration: none;
}
@media screen and (max-width: 991px) {
  .gallary {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }
}
@media screen and (max-width: 650px) {
  .show_images_container h2 {
    font-size: 28px;
    margin-top: 1rem;
  }
  .gallary .pics {
    width: 300px;
    margin-inline: auto;
  }
  .gallary .pics img {
    height: 350px;
    width: 100%;
  }
  .pics_content h3 {
    font-size: 1rem;
  }
  .pics_content p {
    font-size: 0.9rem;
  }
}
@media screen and (max-width: 489px) {
  .gallary .pics {
    width: 180px;
    height: 250px;
  }
  .gallary .pics img {
    height: 220px;
  }
  .pics_content h3 {
    font-size: 0.85rem;
  }
}
/* model */
.model {
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden;
  z-index: 10000;
  cursor: pointer;
}
.model.open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.model img {
  width: auto;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 20px 0 20px;
  margin: 0 auto;
}
.model.open svg {
  position: fixed;
  top: 30px;
  right: 30px;
  font-size: 35px;
  cursor: pointer;
  padding: 1rem;
}
.swiper .swiper-slide {
  display: flex;
  justify-content: center;
  align-items: center;
}
.swiper .swiper-slide a {
  text-decoration: none;
}
.swiper-pagination {
  position: static !important;
}
.gallary .loader {
  width: 48px;
  height: 48px;
  border: 5px solid #fff;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
