@import url("https://fonts.googleapis.com/css2?family=Cairo:wght@200..1000&display=swap");

* {
  font-family: "Cairo", sans-serif;
}
.info,
.info_container {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  color: #fff;
  flex-direction: column;
  font-family: "Cairo", sans-serif;
}
.info_content {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.info_content .info_image_holder {
  width: 50%;
  position: relative;
}
.info_content .info_image_holder img {
  width: 450px;
  height: 450px;
  border-radius: 16px;
}
.info_image_holder .info_image_holder_img1 {
  position: absolute;
  top: 30px;
  right: 20px;
  width: 180px;
  font-size: 2rem;
  font-weight: bold;
}
.info_image_holder .info_image_holder_img2 {
  position: absolute;
  top: 120px;
  right: 180px;
  width: 300px;
  font-size: 2rem;
  font-weight: bold;
}
.info_content .info_content_data {
  width: 50%;
  padding: 1rem;
}
.info_content .info_content_data p {
  font-size: 25px;
}
.info_wrapper {
  padding: 10px;
}
.futuer_view {
  background-image: url("../../images/futuer.jpeg");
  background-position: center;
  background-size: cover;
  padding: 1rem;
  width: 100%;
  text-align: center;
  height: 250px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.info_content_banner {
  width: 100%;
  padding: 0;
  display: block;
  position: relative;
}
.info_content_banner .banner_info {
  position: absolute;
  top: 35%;
  left: 15%;
  font-size: 3rem;
  font-weight: bold;
}
.info_content_banner img {
  width: 100%;
  height: 480px;
}
@media screen and (min-width: 1100px) {
  .info_image_holder .info_image_holder_img1 {
    right: 120px;
    top: 40px;
  }
}
@media screen and (max-width: 789px) {
  .info_content {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  .info_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
  }
  .info_content .info_content_data,
  .info_content .info_image_holder {
    width: 100%;
  }
  .info_content .info_image_holder img {
    height: auto;
    width: 100%;
  }
  .info_content_banner {
    display: block;
  }
  .info_content .info_content_data p {
    font-size: 18px;
    padding-top: 1rem;
    line-height: 2;
  }
  .info_reverse {
    display: flex;
    flex-direction: column-reverse;
  }
  .info_content_banner img {
    height: auto;
  }
  .info_content_banner .banner_info {
    font-size: 2.5rem;
    left: 5%;
  }
  .info_image_holder .info_image_holder_img1 {
    top: 80px;
    font-size: 3.5rem;
    width: 350px;
  }
  .info_image_holder .info_image_holder_img2 {
    font-size: 3rem;
    width: 450px;
    right: 140px;
    top: 280px;
  }
}
@media screen and (max-width: 610px) {
  .info_image_holder .info_image_holder_img2 {
    right: 50px;
    top: 200px;
    font-size: 2.5rem;
  }
}
@media screen and (max-width: 489px) {
  .info_content .info_image_holder img {
    width: 350px;
  }
  .info_image_holder .info_image_holder_img1 {
    font-size: 1.8rem;
    width: 200px;
    top: 25px;
  }
  .info_image_holder .info_image_holder_img2 {
    font-size: 1.5rem;
    right: 70px;
    top: 130px;
    width: 250px;
  }
  .info_content_banner .banner_info {
    font-size: 1.2rem;
    left: 10%;
  }
}
