body {
  overflow: hidden;
}
.warrnig_popup {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.8;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999999;
  display: flex;
  justify-content: center;
  align-items: center;
}
.popup_content {
  width: fit-content;
  position: absolute;
  top: 10%;
  color: #fff;
  opacity: 1;
  border: 5px solid #fff;
  padding: 1rem 5rem;
  margin: 1rem;
}
.popup_content img {
  width: 120px;
}
.popup_content h2 {
  color: rgb(227, 0, 0);
  font-size: 2.5rem;
  margin-bottom: 1rem;
}
.popup_content h3 {
  font-weight: bold;
}
.popup_content button {
  color: #000;
  padding: 10px 15px;
  width: 65px;
  margin: 10px;
  transition: 0.5s ease;
  outline: none;
  border: none;
  opacity: 1;
  text-decoration: none;
  font-weight: bold;
  background-color: #fff;
}
.popup_content button:hover {
  scale: 1.05;
  border-radius: 5px;
}
.popup_content button a {
  color: #000;
  transition: 0.3s ease;
  outline: none;
  border: none;
  text-decoration: none;
}
@media screen and (max-width: 489px) {
  .popup_content {
    padding: 1rem 3rem;
  }
}
