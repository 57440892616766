.carousel {
  margin-top: 3rem;
}
.carousel img {
  height: 550px;
  background-position: center;
  background-size: cover;
}
.carousel .carousel-indicators {
  bottom: -10px;
}
.carousel .carousel-indicators button {
  background-color: #fff;
  width: 60px;
}
.carousel .carousel-control-prev,
.carousel .carousel-control-next {
  width: 60px;
  transition: 0.3s ease;
}
.carousel .carousel-control-prev .carousel-control-prev-icon,
.carousel .carousel-control-next .carousel-control-next-icon {
  background-image: none;
  background-color: #222;
  border-radius: 50%;
  padding: 1.3rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.carousel .carousel-control-prev .carousel-control-prev-icon svg,
.carousel .carousel-control-next .carousel-control-next-icon svg {
  font-size: 25px;
}
.carousel .carousel-control-prev:hover,
.carousel .carousel-control-next:hover {
  opacity: 0.5;
}
@media screen and (max-width: 789px) {
  .carousel img {
    height: auto;
  }
}
@media screen and (max-width: 481px) {
  .carousel img {
    height: auto;
    margin-top: 0.8rem;
  }
  .carousel .carousel-control-prev,
  .carousel .carousel-control-next {
    /* width: 35px; */
    display: none;
  }
  .carousel .carousel-control-prev .carousel-control-prev-icon,
  .carousel .carousel-control-next .carousel-control-next-icon {
    padding: 0;
  }
  .carousel .carousel-control-prev .carousel-control-prev-icon svg,
  .carousel .carousel-control-next .carousel-control-next-icon svg {
    font-size: 15px;
  }
  .carousel .carousel-indicators {
    width: 100px;
    margin-inline: auto;
  }
}
@media screen and (max-width: 320px) {
  .carousel img {
    height: 180px;
  }
}
